/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from "react"
import {
  ChatAltIcon,
  DocumentReportIcon,
  HeartIcon,
  InboxIcon,
  PencilAltIcon,
  ReplyIcon,
  SparklesIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const features = [
  {
    name: "Programme anti-plagiat modulable et adapté",
    description: "à chaque structure d’enseignement",
    icon: InboxIcon,
  },
  {
    name: "Abonnement institutionnel",
    description: "pour une politique forte et homogène.",
    icon: UsersIcon,
  },
  {
    name: "Organisation simple et hiérarchisée",
    description: "le référent Compilatio gère les comptes enseignants.",
    icon: TrashIcon,
  },
  {
    name: "Intégration aux plateformes pédagogiques",
    description:
      "(Moodle, Canvas, Sakai, BlackBoard, Claroline, Dokeos, Chamilo…) pour booster les usages.",
    icon: PencilAltIcon,
  },
  {
    name: "Algorithmes fiables",
    description:
      "dans diverses langues et pour tous formats de documents : Google Drive, Word, Excel, Acrobat….",
    icon: DocumentReportIcon,
  },
  {
    name: "Bases de comparaisons riches",
    description: "avec des établissements partenaires dans le monde entier.",
    icon: ReplyIcon,
  },
  {
    name: "Confidentialité des documents analysés",
    description: "qui sont cryptés et jamais diffusés ou vendus.",
    icon: ChatAltIcon,
  },
  {
    name: "Contenus pédagogiques complémentaires prêts à l’usage",
    description: "(Boîte à Outils Compilatio).",
    icon: HeartIcon,
  },
]
const metrics = [
  {
    id: 1,
    stat: "35",
    emphasis: "Pays",
    rest: "Soit une large empreinte au niveau mondial",
  },
  {
    id: 2,
    stat: "700+",
    emphasis: "Etablissements",
    rest: "qui font confiance aux produits Compilatio",
  },
  {
    id: 3,
    stat: "4 millions +",
    emphasis: "étudiants",
    rest: "qui sont couverts par les abonnements Compilatio",
  },
  {
    id: 4,
    stat: "100 millions +",
    emphasis: "Traitements",
    rest: "Plusieurs millions de documents traités",
  },
]

export default function Compilatio() {
  return (
    <Layout withPattern={true}>
      <Seo title="Logiciel Anti-plagiat | Détection et Prévention du Plagiarisme | GEERD en partenariat avec Compilatio" />
      <main>
        {/* Hero section */}
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <StaticImage
                  className="h-full w-full object-cover"
                  quality={50}
                  src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                  alt="People working on laptops"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-pink-700 to-rose-900 mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white">Compilatio Magister</span>
                  <span className="block text-rose-200">
                    pour des travaux sans plagiat
                  </span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center text-xl text-rose-200 sm:max-w-3xl">
                  En tant qu’enseignant, accompagnez vos classes dans leurs
                  recherches documentaires et formez-les au respect de la
                  propriété intellectuelle et du droit d’auteur.
                </p>
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5">
                    <Link
                      to="/contact"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-rose-700 bg-white hover:bg-rose-50 sm:px-8"
                    >
                      Commencer
                    </Link>
                    {/* <a
                      href="#"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-rose-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                    >
                      Demander une démo
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative pt-16 pb-32 overflow-hidden">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
          />
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-pink-700 to-rose-900">
                      <InboxIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Enjeux d’une prévention du plagiat active dans
                      l’enseignement secondaire et supérieur
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Les métiers de l’enseignement ont pour missions
                      d’inculquer de nouvelles connaissances et compétences,
                      d’aider à trouver des solutions face à des problématiques
                      et de développer l’esprit critique des étudiants. En tant
                      qu’enseignant, vous transmettez des valeurs qui forment de
                      futurs citoyens responsables : intégrité, honnêteté,
                      authenticité.
                    </p>
                    {/* <div className="mt-6">
                      <a
                        href="#"
                        className="inline-flex bg-gradient-to-r from-pink-700 to-rose-900 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-pink-700 hover:to-rose-700"
                      >
                        Get started
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">
                        &ldquo;J’adore découvrir des services qui s'inscrivent
                        dans une démarche pédagogique. Dans le secondaire, les
                        élèves sont là pour apprendre!.&rdquo;
                      </p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="flex-shrink-0">
                          <StaticImage
                            className="h-6 w-6 rounded-full"
                            quality={50}
                            width={50}
                            src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                            alt=""
                          />
                        </div>
                        <div className="text-base font-medium text-gray-700">
                          Marie, Enseignante-documentaliste dans un lycée
                          général
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <StaticImage
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    width={800}
                    src="https://www.compilatio.net/media/cache/img_text/2020/10/6149-magister-photo-1.jpg"
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-pink-700 to-rose-900">
                      <SparklesIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Pourquoi Magister de Compilatio plutôt qu’un autre
                      logiciel anti-plagiat ?
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Fort de sa notoriété et expertise reconnues depuis 2005,
                      vous profiterez d'un accompagnement sur mesure et d'une
                      base de comparaison riche avec des établissements du monde
                      entier, ainsi que d'une intégration avec vos outils LMS
                      déjà existants.
                    </p>
                    {/* <div className="mt-6">
                      <a
                        href="#"
                        className="inline-flex bg-gradient-to-r from-pink-700 to-rose-900 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-pink-700 hover:to-rose-700"
                      >
                        Get started
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <StaticImage
                    width={800}
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="https://www.compilatio.net/media/cache/img_text/2020/10/6337-magister-photo-2.jpg"
                    alt="Customer profile user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Gradient Feature Section */}
        <div className="bg-gradient-to-r from-pink-800 to-rose-900">
          <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
            <h2 className="text-3xl font-extrabold text-white tracking-tight">
              Pourquoi Magister de Compilatio plutôt qu’un autre logiciel
              anti-plagiat ?
            </h2>
            <p className="mt-4 max-w-3xl text-lg text-pink-200">
              Fort de sa notoriété et expertise reconnues depuis 2005, vous
              profiterez d'un accompagnement sur mesure et d'une base de
              comparaison riche avec des établissements du monde entier, ainsi
              que d'une intégration avec vos outils LMS déjà existants.
            </p>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
              {features.map(feature => (
                <div key={feature.name}>
                  <div>
                    <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h3 className="text-lg font-medium text-white">
                      {feature.name}
                    </h3>
                    <p className="mt-2 text-base text-pink-200">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Stats section */}

        <div className="relative bg-gray-900">
          <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
            <div className="h-full w-full xl:grid xl:grid-cols-2">
              <div className="h-full xl:relative xl:col-start-2">
                <StaticImage
                  quality={50}
                  className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                  src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                  alt="People working on laptops"
                />
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                />
              </div>
            </div>
          </div>
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
            <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
              <h2 className="text-sm font-semibold tracking-wide uppercase">
                <span className="bg-gradient-to-r from-pink-300 to-rose-300 bg-clip-text text-transparent">
                  Quelques Chiffres
                </span>
              </h2>
              <p className="mt-3 text-3xl font-extrabold text-white">
                Avec Compilatio Magister, faites le choix de la performance
              </p>
              {/* <p className="mt-5 text-lg text-gray-300">
                Rhoncus sagittis risus arcu erat lectus bibendum. Ut in
                adipiscing quis in viverra tristique sem. Ornare feugiat viverra
                eleifend fusce orci in quis amet. Sit in et vitae tortor, massa.
                Dapibus laoreet amet lacus nibh integer quis. Eu vulputate diam
                sit tellus quis at.
              </p> */}
              <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
                {metrics.map(item => (
                  <p key={item.id}>
                    <span className="block text-2xl font-bold text-white">
                      {item.stat}
                    </span>
                    <span className="mt-1 block text-base text-gray-300">
                      <span className="font-medium text-white">
                        {item.emphasis}
                      </span>{" "}
                      {item.rest}
                    </span>
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-white">
          <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              <span className="block">
                Prêt à développer la culture d'intégrité dans votre
                établissement ?
              </span>
              <span className="-mb-1 pb-1 block bg-gradient-to-r from-pink-700 to-rose-900 bg-clip-text text-transparent">
                Contactez-nous
              </span>
            </h2>
            <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
              <Link
                to="/contact"
                className="flex items-center justify-center bg-gradient-to-r from-pink-700 to-rose-900 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-pink-700 hover:to-rose-700"
              >
                En Savoir Plus
              </Link>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
